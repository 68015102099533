import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useRecoilValue } from "recoil";
import {
  Box,
  Avatar,
  Typography,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
  Paper
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { firebaseState } from "../../store/AuthAtoms";
const CONVERSATIONS = "consultantConversations";
const processChanges = (prev, changes, object = false, collection = null) => {
  const _prev = object ? { ...prev } : [...prev];

  changes.forEach(change => {
    const index = object ? -1 : _prev.findIndex(group => group.id === change.doc.id);
    if (change.type === "added" || change.type === "modified") {
      if (object) {
        _prev[change.doc.id] = { ...change.doc.data(), id: change.doc.id };
      } else if (index === -1) {
        _prev.push({ ...change.doc.data(), id: change.doc.id });
      } else {
        _prev[index] = { ...change.doc.data(), id: change.doc.id };
      }
    } else if (change.type === "removed") {
      if (object) {
        delete _prev[change.doc.id];
      } else if (index !== -1) {
        _prev.splice(index, 1);
      }
    }
  });
  return _prev;
};

const ConsultantChat = ({ diagramId }) => {
  const firebase = useRecoilValue(firebaseState);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (!firebase) return;
    const diagramsQuery = firebase.db.collection(CONVERSATIONS).where("deleted", "==", false);
    const unsubscribeDiagrams = diagramsQuery.onSnapshot(snapshot => {
      const changes = snapshot.docChanges();
      setMessages(prev => processChanges(prev, changes));
    });
    return () => {
      unsubscribeDiagrams();
    };
  }, [firebase]);

  const handleSendMessage = async e => {
    e.preventDefault();
    if (!inputMessage.trim()) return;
    const messageId = firebase.db.collection("some-collection").doc().id;
    const userMessage = {
      id: messageId,
      sender: "user",
      text: inputMessage,
      timestamp: new Date(),
      isConsultant: false
    };

    setMessages(prev => [...prev, userMessage]);
    setInputMessage("");
    setIsLoading(true);

    try {
      await axios.post("/consultantapi", {
        prompt: inputMessage,
        messageId,
        diagramId
      });
    } catch (error) {
      console.error("Error sending message:", error);
      const errorMessage = {
        id: Date.now() + 1,
        sender: "consultant",
        text: "Sorry, I'm having trouble responding right now.",
        timestamp: new Date(),
        isConsultant: true,
        isError: true
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      elevation={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "80vh",
        width: "100%",
        overflow: "hidden",
        border: "1px solid gray",
        borderRadius: "25px"
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          bgcolor: "background.default"
        }}
      >
        {messages.length === 0 && !isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "text.secondary",
              mt: "200px"
            }}
          >
            <Typography>Start your conversation </Typography>
          </Box>
        ) : (
          <List>
            {messages.map(message => (
              <React.Fragment key={message.id}>
                <ListItem
                  sx={{
                    justifyContent: message.isConsultant ? "flex-start" : "flex-end",
                    alignItems: "flex-start"
                  }}
                >
                  {message.isConsultant && (
                    <ListItemAvatar>
                      <Avatar src={message.sender.avatar} alt={message.sender.name} />
                    </ListItemAvatar>
                  )}
                  <Paper
                    elevation={2}
                    sx={{
                      p: "7px",
                      maxWidth: "70%",
                      bgcolor: message.isConsultant
                        ? message.isError
                          ? "error.light"
                          : "primary.light"
                        : "secondary.main",
                      color: message.isConsultant
                        ? message.isError
                          ? "error.contrastText"
                          : "primary.contrastText"
                        : "secondary.contrastText",
                      borderRadius: message.isConsultant ? "4px 20px 20px 20px" : "20px 4px 20px 20px"
                    }}
                  >
                    <ListItemText
                      primary={message.text}
                      secondaryTypographyProps={{
                        color: message.isConsultant
                          ? message.isError
                            ? "error.contrastText"
                            : "primary.contrastText"
                          : "secondary.contrastText",
                        variant: "caption"
                      }}
                    />
                  </Paper>
                  {!message.isConsultant && (
                    <ListItemAvatar sx={{ ml: 1 }}>
                      <Avatar src={message.sender.avatar} alt={message.sender.name} />
                    </ListItemAvatar>
                  )}
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        )}
        {isLoading && (
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Avatar src={"consultant.avatar"} alt={"consultant.name"} sx={{ mr: 1 }} />
            <CircularProgress size={24} />
          </Box>
        )}
      </Box>

      <Box
        component="form"
        onSubmit={handleSendMessage}
        sx={{
          display: "flex",
          p: 1,
          bgcolor: "background.paper",
          borderTop: "1px solid",
          borderColor: "divider",
          position: "sticky",
          bottom: 0
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={inputMessage}
          onChange={e => setInputMessage(e.target.value)}
          placeholder="Type your message here..."
          disabled={isLoading}
          sx={{ mr: 1 }}
          multiline
        />
        <IconButton type="submit" color="primary" disabled={isLoading || !inputMessage.trim()}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ConsultantChat;
