import React, { useRef, useEffect, useState, useCallback } from "react";
import { useRecoilValue } from "recoil";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { firebaseState } from "../store/AuthAtoms";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useThemeContext } from "../ThemeContext";
import useConfirmDialog from "../hooks/useConfirmDialog";
import usePromptDialog from "../hooks/usePromptDialog";
import axios from "axios";
import dagreD3 from "dagre-d3";
import "./OneCademyCollaborationModel.css";
import { Select } from "@mui/material";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import MenuIcon from "@mui/icons-material/Menu";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import WbSunnyIcon from "@mui/icons-material/WbSunny";

import { LoadingButton } from "@mui/lab";

import AddNodeTypeModal from "./CollaborativeModel/AddNodeTypeModal";
import CollabTree from "./CollabTree/CollabTree";

import PromptViewer from "./CollaborativeModel/PromptViewer";
import ReinforcementLoopsDisplay from "../Components/CollaborativeModel/ReinforcementLoopsDisplay";
import NodeEditor from "../Components/CollaborativeModel/NodeEditor";
import LinkEditor from "../Components/CollaborativeModel/LinkEditor";
import SolutionsTab from "../Components/CollaborativeModel/SolutionsTab";

import { getColor, changeAlphaColor } from "../utils";
const d3 = require("d3");

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

// const NODE_TYPES = ["Positive Outcome", "Negative Outcome", "Design Features"];

const NODE_TYPES = "nodeTypes";
const COLLAB_MODEL_NODES = "collabModelNodes";
const GROUPS = "groups";
const LINKS = "links";
const NODES = "nodes";
const DIAGRAMS = "diagrams";
const DIAGRAM_SOLUTIONS = "diagramSolutions";
const LINKS_TYPES = {
  "known positive": { text: "Known Positive Effect", color: "#4caf50" },
  "hypothetical positive": { text: "Hypothetical Positive Effect", color: "#1BBAE4" },
  "known negative": { text: "Known Negative Effect", color: "#A91BE4" },
  "hypothetical negative": { text: "Hypothetical Negative Effect", color: "#E4451B" }
};

const IdeaEvaluator = () => {
  const firebase = useRecoilValue(firebaseState);
  const svgRef = useRef();
  const { darkMode, toggleTheme } = useThemeContext();
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);

  const [newNode, setNewNode] = useState(null);

  const [selectedLink, setSelectedLink] = useState(null);
  const [zoomState, setZoomState] = useState(null);
  const [openSideBar, setOpenSideBar] = useState(true);
  const [selectedDiagram, setSelectedDiagram] = useState({ id: "", title: "" });
  const [nodeTypes, setNodeTypes] = useState({});
  const [isModalAddTypeOpen, setIsModalAddTypeOpen] = useState(false);
  const [editNodeType, setEditNodeType] = useState(null);
  const [groups, setGroups] = useState([]);
  const [links, setLinks] = useState([]);
  const [nodes, setNodes] = useState({});
  const [selectedGroups, setSelectedGroups] = useState({});
  const [diagrams, setDiagrams] = useState([]);

  const { promptIt, ConfirmDialog, confirmIt } = useConfirmDialog();
  const { promptItDiagram, PromptDialog } = usePromptDialog();
  const [loadingResponse, setLoadingResponse] = useState(null);
  const [proposedSolutions, setProposedSolutions] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [tempText, setTempText] = useState("");
  const [reviewerFeedback, setReviewerFeedback] = useState(
    null /* {
    evaluation: "accept",
    feedback:
      "Your JSON object demonstrates a solid grasp of the required top-level structure and includes logically consistent links and node fields; however, several important issues prevent acceptance. First, many supporting quotes are paraphrased or rearranged rather than provided verbatim as instructed. Second, some key variables mentioned in the case—such as insurance, parking, technology challenges, and competition—are omitted or only loosely aggregated under broader nodes, leaving out important causal influences. Third, there are additional leverage points (for instance, pricing variables or marketing efforts) and known relationships in the text that are either missing or overly simplified. Finally, although your links properly distinguish between “known” and “hypothetical,” the document explicitly supports more relationships than are represented, and certain causal connections (for example, between charging higher daily rates and its direct effect on profitability) could be further elaborated. Strengthening these areas by incorporating truly verbatim quotes, capturing more of the critical variables and feedback loops, and identifying the relevant known links would bring your diagram fully in line with the original instructions."
  } */
  );
  const [reviewerPrompt, setReviewerPrompt] = useState(null);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [reinforcementLoops, setReinforcementLoops] = useState([]);

  const [selectedLoop, setSelectedLoop] = useState(null);
  const [selectedSolutionId, setSelectedSolutionId] = useState(null);

  const editor = true;
  const getReinforcementLoops = links => {
    const graph = {};
    const cycles = [];

    links.forEach(link => {
      if (!graph[link.source]) graph[link.source] = [];
      graph[link.source].push({ target: link.target, polarity: link.polarity });
    });

    function dfs(node, visited, stack, path) {
      if (stack[node]) {
        const cycleIndex = path.indexOf(node);
        if (cycleIndex !== -1) {
          const cycle = path.slice(cycleIndex);
          cycles.push(cycle);
        }
        return;
      }

      if (visited[node]) return;

      visited[node] = true;
      stack[node] = true;
      path.push(node);

      if (graph[node]) {
        graph[node].forEach(neighbor => {
          dfs(neighbor.target, visited, stack, path);
        });
      }

      stack[node] = false;
      path.pop();
    }

    const visited = {};
    const stack = {};
    Object.keys(graph).forEach(node => {
      dfs(node, visited, stack, []);
    });

    function classifyLoop(cycle) {
      let hasPositive = false;
      let hasNegative = false;

      for (let i = 0; i < cycle.length; i++) {
        const source = cycle[i];
        const target = cycle[(i + 1) % cycle.length];
        const link = links.find(l => l.source === source && l.target === target);
        if (link) {
          if (link.polarity === "positive") hasPositive = true;
          if (link.polarity === "negative") hasNegative = true;
        }
      }

      if (hasPositive && hasNegative) return "Balancing Loop";
      if (hasPositive) return "Positive Reinforcing Loop";
      if (hasNegative) return "Negative Reinforcing Loop";
    }

    const result = {
      "Positive Reinforcing Loop": [],
      "Negative Reinforcing Loop": [],
      "Balancing Loop": []
    };

    cycles.forEach(cycle => {
      const loopType = classifyLoop(cycle);
      result[loopType].push({ loopNodes: cycle });
    });
    /*     for (let key in result) {
      if (result[key].length <= 0) {
        delete result[key];
      }
    } */
    return result;
  };

  const changeFavicon = iconURL => {
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = iconURL;
      document.head.appendChild(newLink);
    } else {
      link.href = iconURL;
    }
  };

  useEffect(() => {
    changeFavicon("/faviconMT.png");
  }, []);

  const ColorBox = props => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: props.color,
          color: "white",
          fontSize: 13,
          borderRadius: 2,
          maxWidth: 90,
          ml: 0.9,
          mr: 0.5,
          mb: 0.5,
          textAlign: "center",
          width: "100%",
          height: "40px",
          position: "relative",
          cursor: editor ? "pointer" : ""
        }}
        key={props.text}
        onClick={() => {
          if (!editor) return;
          setEditNodeType({
            color: props.color,
            type: props.text,
            id: props.id
          });
          setIsModalAddTypeOpen(true);
        }}
      >
        {props.text}
      </Box>
    );
  };
  const addPencilButton = (edgeElement, edgeData, pencilButtonsGroup) => {
    let edgeLabel = edgeElement.select("path");
    let edgePath = edgeLabel.node();
    let pathLength = edgePath.getTotalLength();
    let positionRatio = 0.7;
    let point = edgePath.getPointAtLength(pathLength * positionRatio);

    let circleBg = pencilButtonsGroup
      .append("circle")
      .attr("cx", point.x)
      .attr("cy", point.y)
      .attr("r", 12)
      .attr("fill", "rgba(0, 0, 0, 0.1)")
      .style("opacity", 0)
      .style("transition", "opacity 0.2s ease-in-out");

    let button = pencilButtonsGroup
      .append("foreignObject")
      .attr("width", 20)
      .attr("height", 20)
      .attr("x", point.x - 10)
      .attr("y", point.y - 10)
      .attr("class", "pencil-button")
      .style("z-index", "19999")
      .style("cursor", "pointer");

    let buttonBody = button.append("xhtml:body").style("margin", "0px").style("padding", "0px");

    buttonBody
      .append("xhtml:button")
      .style("cursor", "pointer")
      .style("background", "transparent")
      .style("color", "black")
      .style("border", "none")
      .style("font-weight", "bold")
      .style("width", "100%")
      .style("height", "100%")

      .text("✏️")
      .on("click", function (e) {
        e.stopPropagation();
        modifyLink(edgeData);
      })
      .on("mouseenter", function () {
        circleBg.style("opacity", 1);
      })
      .on("mouseleave", function () {
        circleBg.style("opacity", 0);
      });

    pencilButtonsGroup
      .append("text")
      .attr("class", "custom-text-color")
      .attr("x", point.x)
      .attr("y", point.y + 14)
      .attr("font-family", "Arial, sans-serif")
      .attr("font-size", "15px")
      .style("cursor", "pointer");
  };

  useEffect(() => {
    const unsubscribe = firebase.db.collection(NODE_TYPES).onSnapshot(snapshot => {
      const newNodeTypes = {};
      snapshot.forEach(doc => {
        const data = doc.data();
        newNodeTypes[data.type.toLowerCase()] = { ...doc.data(), id: doc.id };
      });
      setNodeTypes(newNodeTypes);
    });

    return unsubscribe;
  }, [firebase]);

  const saveNewType = async (type, color, editedNodeType) => {
    if (editedNodeType) {
      const nodeTypeRef = firebase.db.collection(NODE_TYPES).doc(editedNodeType.id);
      nodeTypeRef.update({
        type,
        color
      });

      const nodesDocsSnapshot = await firebase.db
        .collection(COLLAB_MODEL_NODES)
        .where("type", "==", editedNodeType.type)
        .get();

      const batch = firebase.db.batch();

      nodesDocsSnapshot.forEach(doc => {
        batch.update(doc.ref, { type });
      });

      await batch.commit();
    } else {
      const newTypeRef = firebase.db.collection(NODE_TYPES).doc();
      await newTypeRef.set({
        type: type,
        color,
        createdAt: new Date()
      });
    }
  };
  const processChanges = (prev, changes, object = false, collection = null) => {
    const _prev = object ? { ...prev } : [...prev];

    changes.forEach(change => {
      const index = object ? -1 : _prev.findIndex(group => group.id === change.doc.id);
      if (change.type === "added" || change.type === "modified") {
        if (object) {
          _prev[change.doc.id] = { ...change.doc.data(), id: change.doc.id };
        } else if (index === -1) {
          _prev.push({ ...change.doc.data(), id: change.doc.id });
        } else {
          _prev[index] = { ...change.doc.data(), id: change.doc.id };
        }
      } else if (change.type === "removed") {
        if (object) {
          delete _prev[change.doc.id];
        } else if (index !== -1) {
          _prev.splice(index, 1);
        }
      }
    });
    return _prev;
  };
  useEffect(() => {
    if (!selectedDiagram?.id) {
      return;
    }
    const diagramsQuery = firebase.db
      .collection(DIAGRAM_SOLUTIONS)
      .where("diagramId", "==", selectedDiagram.id)
      .where("deleted", "==", false);
    const unsubscribeDiagrams = diagramsQuery.onSnapshot(snapshot => {
      const changes = snapshot.docChanges();
      setProposedSolutions(prev => processChanges(prev, changes));
    });
    return () => {
      unsubscribeDiagrams();
    };
  }, [firebase.db, selectedDiagram?.id]);

  useEffect(() => {
    const diagramsQuery = firebase.db
      .collection(DIAGRAMS)
      .where("ideaEvaluator", "==", true)
      .where("deleted", "==", false);
    const unsubscribeDiagrams = diagramsQuery.onSnapshot(snapshot => {
      const changes = snapshot.docChanges();
      setDiagrams(prev => processChanges(prev, changes));
    });
    return () => {
      unsubscribeDiagrams();
    };
  }, [firebase.db]);

  useEffect(() => {
    if (!selectedDiagram?.id) {
      setSelectedDiagram(diagrams[0]);
    }
  }, [diagrams, selectedDiagram?.id]);

  useEffect(() => {
    setReinforcementLoops(getReinforcementLoops(links));
  }, [links]);

  useEffect(() => {
    if (!selectedSolutionId && !selectedDiagram?.id) {
      setNodes([]);
      setGroups([]);
      setLinks([]);
      return;
    }
    setNodes([]);
    setGroups([]);
    setLinks([]);

    const groupsQuery = firebase.db
      .collection(GROUPS)
      .where("diagrams", "array-contains", selectedSolutionId || selectedDiagram.id)
      .where("deleted", "==", false);
    const linksQuery = firebase.db
      .collection(LINKS)
      .where("diagrams", "array-contains", selectedSolutionId || selectedDiagram.id)
      .where("deleted", "==", false);
    const nodesQuery = firebase.db
      .collection(NODES)
      .where("diagrams", "array-contains", selectedSolutionId || selectedDiagram.id)
      .where("deleted", "==", false);

    const unsubscribeGroups = groupsQuery.onSnapshot(snapshot => {
      const changes = snapshot.docChanges();
      setGroups(prev => processChanges(prev, changes));
    });

    const unsubscribeLinks = linksQuery.onSnapshot(snapshot => {
      const changes = snapshot.docChanges();
      setLinks(prev => processChanges(prev, changes, false, "links"));
    });

    const unsubscribeNodes = nodesQuery.onSnapshot(snapshot => {
      const changes = snapshot.docChanges();
      setNodes(prev => processChanges(prev, changes, true));
    });

    return () => {
      unsubscribeGroups();
      unsubscribeLinks();
      unsubscribeNodes();
    };
  }, [firebase.db, selectedDiagram?.id, selectedSolutionId]);

  useEffect(() => {
    var g = new dagreD3.graphlib.Graph({ compound: true })
      .setGraph({ rankdir: "LR", isMultigraph: true })
      .setDefaultEdgeLabel(function () {
        return {};
      });
    d3.select("svg g").remove();

    for (let visibleNodeId in nodes) {
      if (nodes[visibleNodeId] && !g.nodes().includes(visibleNodeId)) {
        const nodeData = nodes[visibleNodeId];
        const isBlurred =
          selectedGroups[selectedDiagram.id] &&
          !(selectedGroups[selectedDiagram.id] || new Set()).has(nodeData.groups[0].id);
        let nodeColor = getColor(nodeData.nodeType, nodeTypes, isBlurred ? 0.1 : 1);
        const textColor = changeAlphaColor(!darkMode && isBlurred ? "#000000" : "#fff", isBlurred ? 0.1 : 1);
        const borderColor = changeAlphaColor("#ffa500", isBlurred ? 0.1 : 1);

        g.setNode(nodeData.id, {
          label: nodeData.label,
          style: nodeData.isLeverage
            ? `fill: ${nodeColor}; stroke: ${borderColor}; stroke-width: 7px;`
            : `fill: ${nodeColor};`,
          labelStyle: `fill: ${textColor};`
        });
      }
    }

    for (let { source, target, polarity, certainty } of links) {
      if (target && source) {
        const isHighlighted =
          !selectedGroups[selectedDiagram.id] ||
          (selectedGroups[selectedDiagram.id].has(nodes[source]?.groups[0]?.id) &&
            selectedGroups[selectedDiagram.id].has(nodes[target]?.groups[0]?.id));
        const color = LINKS_TYPES[`${certainty.trim()} ${polarity.trim()}`]?.color || "";

        const adjustedColor = changeAlphaColor(color, isHighlighted ? 1 : 0.1);

        let _arrowheadStyle = `fill: ${adjustedColor};`;
        let _style = `stroke: ${adjustedColor}; stroke-width: 4px;`;
        const elementsSet = new Set(selectedLoop?.loopNodes || []);
        let label = "";
        let labelStyle = "";
        if (
          (selectedLink?.v === source && selectedLink?.w === target) ||
          (elementsSet.has(source) && elementsSet.has(target))
        ) {
          const color = darkMode ? "white" : "#212121";
          _style = `stroke: ${color}; stroke-width: 3px; filter: drop-shadow(3px 3px 5px ${color}); opacity: 1;`;
          _arrowheadStyle = `fill: ${color}; opacity: 1;`;
          label = polarity === "positive" ? "+" : polarity === "negative" ? "-" : "";
          labelStyle = `font-size: 18px; fill: ${
            polarity === "positive" ? "green" : polarity === "negative" ? "red" : ""
          }; font-weight: bold;`;
        }

        if (g.nodes().includes(source) && g.nodes().includes(target)) {
          g.setEdge(source, target, {
            curve: d3.curveBasis,
            style: _style,
            arrowheadStyle: _arrowheadStyle,
            label,
            labelStyle
          });
        }
      }
    }

    g.nodes().forEach(function (v) {
      var node = g.node(v);
      if (node) {
        node.rx = node.ry = 5;
      }
    });

    var render = new dagreD3.render();

    const svg = d3.select("svg");
    const svgGroup = svg.append("g");

    render(svgGroup, g);

    svgGroup.selectAll("g.node").each(function (v) {
      let nodeElement = d3.select(this);
      let nodeLabel = nodeElement.select("rect");
      let nodeBBox = nodeLabel.node().getBBox();

      nodeElement
        .style("cursor", "pointer")
        .on("mouseover", function () {
          nodeLabel.style("fill-opacity", 0.8);
        })
        .on("mouseout", function () {
          nodeLabel.style("fill-opacity", 1);
        });
      let button = nodeElement
        .append("foreignObject")
        .attr("width", 20)
        .attr("height", 20)
        .attr("x", nodeBBox.width / 2 - 10)
        .attr("y", -nodeBBox.height / 2 - 10)
        .attr("class", "hide-button")
        .style("cursor", "pointer");

      let buttonBody = button.append("xhtml:body").style("margin", "0px").style("padding", "0px");

      /*   if (editor) {
        buttonBody
          .append("xhtml:button")
          .style("background", "white")
          .style("color", "black")
          .style("border", "1px solid black")
          .style("border-radius", "50%")
          .style("width", "20px")
          .style("height", "20px")
          .style("font-weight", "bold")
          .style("font-size", "12px")
          .style("line-height", "18px")
          .style("text-align", "center")
          .style("padding", "0")
          .text("X")
          .on("click", function (e) {
            e.stopPropagation();
            removeNode(v);
          })
          .on("mouseover", function () {
            d3.select(this).style("background", "orange");
          })
          .on("mouseout", function () {
            d3.select(this).style("background", "white");
          });
      } */
      if (!!newNode) {
        let button2 = nodeElement
          .append("foreignObject")
          .attr("width", 20)
          .attr("height", 20)
          .attr("x", nodeBBox.x + nodeBBox.width / 2 - 10)
          .attr("y", nodeBBox.y - 10)
          .attr("class", "hide-button");

        var buttonBody2 = button2.append("xhtml:body").style("margin", "0px").style("padding", "0px");
        const tooltip = d3
          .select("body")
          .append("div")
          .attr("class", "tooltip")
          .style("position", "absolute")
          .style("visibility", "hidden")
          .style("background-color", "rgba(0,0,0,0.7)")
          .style("color", "white")
          .style("padding", "5px")
          .style("border-radius", "4px")
          .style("font-size", "12px");

        if (newNode.children.includes(v)) {
          buttonBody2
            .append("xhtml:button")
            .style("background", "white")
            .style("border", "1px solid black")
            .style("border-radius", "50%")
            .style("width", "20px")
            .style("height", "20px")
            .style("font-size", "25px")
            .style("line-height", "10px")
            .style("padding", "0")
            .style("text-align", "center")
            .style("cursor", "pointer")
            .style("padding-bottom", "3px")
            .text("-")
            .on("click", function (e) {
              e.stopPropagation();
              removeChild(v);
              tooltip.style("visibility", "hidden");
            })
            .on("mouseover", function (event) {
              d3.select(this).style("background", "#FFCC80"); // light orange
              tooltip
                .style("visibility", "visible")
                .text("Remove as child")
                .style("left", event.pageX + 10 + "px")
                .style("top", event.pageY + 10 + "px");
            })
            .on("mouseout", function () {
              d3.select(this).style("background", "white");
              tooltip.style("visibility", "hidden");
            });
        } else {
          buttonBody2
            .append("xhtml:button")
            .style("background", "white")
            .style("color", "black")
            .style("border", "1px solid black")
            .style("border-radius", "50%")
            .style("width", "20px")
            .style("height", "20px")
            .style("font-size", "25px")
            .style("line-height", "2px")
            .style("padding", "0")
            .style("text-align", "center")
            .style("cursor", "pointer")
            .text("+")
            .on("click", function (e) {
              e.stopPropagation();
              addChild(v);
              tooltip.style("visibility", "hidden");
            })
            .on("mouseover", function (event) {
              d3.select(this).style("background", "orange");
              tooltip
                .style("visibility", "visible")
                .text("Add as child")
                .style("left", event.pageX + 10 + "px")
                .style("top", event.pageY + 10 + "px");
            })
            .on("mouseout", function () {
              d3.select(this).style("background", "white");
              tooltip.style("visibility", "hidden");
            });
        }
      }
    });

    const pencilButtonsGroup = svg.append("g");

    const svgWidth = (window.innerWidth * 70) / 100;
    const svgHeight = 600;
    const graphWidth = g.graph().width + 50;
    const graphHeight = g.graph().height + 50;

    const zoomScale = Math.min(svgWidth / graphWidth, svgHeight / graphHeight);
    const translateX = (svgWidth - graphWidth * zoomScale) / 2;
    const translateY = (svgHeight - graphHeight * zoomScale) / 2;

    const zoom = d3.zoom().on("zoom", function (d) {
      svgGroup.attr("transform", d3.zoomTransform(this));
      pencilButtonsGroup.attr("transform", d3.zoomTransform(this));
      setZoomState(d3.zoomTransform(this));
    });
    if (!zoomState || Number.isNaN(zoomState?.x)) {
      svg.call(zoom.transform, d3.zoomIdentity.translate(translateX, translateY).scale(zoomScale));
    } else {
      svgGroup.attr("transform", zoomState);
      pencilButtonsGroup.attr("transform", zoomState);
    }
    svg.call(zoom);

    const gNodes = svg.selectAll("g.node");
    if (editor) {
      gNodes.on("click", function (d) {
        d.stopPropagation();
        modifyNode(d.target.__data__);
      });
    }
    var edges = svg.selectAll("g.edgePath");
    if (editor) {
      edges.each(function (edgeData) {
        var edgeElement = d3.select(this);
        const nodeData = nodes[edgeData.v];
        if (!nodeData) return;
        /*  const childIdx = nodeData.children.findIndex(child => child.id === edgeData.w);
        if (childIdx === -1) return;
        const order = nodeData.children[childIdx].order; */
        addPencilButton(edgeElement, edgeData, pencilButtonsGroup);
      });
    }
    if (!editor) {
      edges.on("click", function (d) {
        setSelectedLink(null);
        showLinkDetails(d.target.__data__);
      });
    }

    return () => {
      d3.select("svg g").remove();
    };
  }, [selectedLink, darkMode, nodeTypes, nodes, links, selectedGroups, newNode, selectedLoop]);

  const AddNewNode = second => {
    setSelectedLink(null);
    setNewNode({
      label: "",
      nodeType: "process variable",
      isLeverage: false,
      groups: [],
      diagrams: [],
      children: [],
      nodeType: "",
      new: true
    });
    setTabIndex(reviewerFeedback ? 3 : 2);
    setOpenSideBar(true);
  };

  const handleClose = () => {
    setSelectedLink(null);
    setNewNode(null);
    setTabIndex(0);
  };

  const handleSave = useCallback(async () => {
    try {
      const _newNode = JSON.parse(JSON.stringify(newNode));
      setNewNode(null);
      setTabIndex(0);
      if (!selectedDiagram?.id) return;
      if (_newNode?.new) {
        const newNodeRef = firebase.db.collection(NODES).doc();
        _newNode.groups = _newNode.groups.map(c => {
          return {
            label: c.label,
            id: c.id
          };
        });

        await newNodeRef.set({
          label: _newNode.label,
          nodeType: _newNode.nodeType,
          diagrams: [selectedDiagram.id],
          groups: _newNode.groups,
          isLeverage: _newNode.isLeverage,
          id: newNodeRef.id,
          deleted: false
        });
        for (let child of _newNode.children) {
          const newLinkRef = firebase.db.collection("links").doc();
          const newLink = {
            source: newNodeRef.id,
            target: child,
            certainty: "known",
            polarity: "positive",
            diagrams: [selectedDiagram.id],
            detail: ""
          };
          newLinkRef.set(newLink);
        }
      } else if (!!_newNode.previous) {
        const previousChildren = links.filter(c => c.source === _newNode.id);
        for (let previous of previousChildren) {
          if (!_newNode.children.includes(previous.target)) {
            const linkRef = firebase.db.collection(LINKS).doc(previous.id);
            linkRef.update({ deleted: true });
          }
        }
        for (let child of _newNode.children) {
          const indx = previousChildren.findIndex(c => c.target === child);
          if (indx === -1) {
            const newLinkRef = firebase.db.collection("links").doc();
            const newLink = {
              id: newLinkRef.id,
              source: _newNode.id,
              target: child,
              certainty: "known",
              polarity: "positive",
              diagrams: [selectedDiagram.id],
              detail: "",
              deleted: false
            };

            await newLinkRef.set(newLink);
          }
        }
        const newNodeRef = firebase.db.collection(NODES).doc(_newNode.id);

        _newNode.groups = _newNode.groups.map(c => {
          return {
            label: c.label,
            id: c.id
          };
        });
        await newNodeRef.update({
          label: _newNode.label,
          nodeType: _newNode.nodeType,
          diagrams: [selectedDiagram.id],
          groups: _newNode.groups,
          id: newNodeRef.id,
          isLeverage: _newNode.isLeverage,
          deleted: false
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [firebase.db, links, newNode, selectedDiagram?.id]);

  const modifyNode = async nodeId => {
    const children = [];
    for (let link of links) {
      if (link.source === nodeId) {
        children.push(link.target);
      }
    }
    setNewNode({ ...nodes[nodeId], children, previous: true });
    setTabIndex(reviewerFeedback ? 3 : 2);
    setOpenSideBar(true);
    setSelectedLink(null);
  };

  const deleteNode = async () => {
    if (!newNode) return;
    if (await confirmIt("Are you sure you want to delete this node?", "Delete", "Keep")) {
      const nodeRef = firebase.db.collection(NODES).doc(newNode.id);
      nodeRef.update({ deleted: true });
      setNewNode(null);
      setTabIndex(0);
    }
  };

  const showLinkDetails = async data => {
    /*    const nodeId = data.v;
    const childId = data.w;
    const nodeDoc = await firebase.firestore().collection(COLLAB_MODEL_NODES).doc(nodeId).get();
    const nodeData = nodeDoc.data();
    const children = nodeData.children;
    const child = children.find(child => child.id === childId);
    setExplanationLink(child.explanation);
    if (child.explanation !== "") {
      setSelectedLink(data);
    } */
  };

  const modifyLink = async data => {
    const nodeId = data.v;
    const childId = data.w;
    const link = links.find(link => link.source === nodeId && link.target === childId);

    setSelectedLink({ ...link, ...data });
    setTabIndex(reviewerFeedback ? 3 : 2);
    setOpenSideBar(true);
    setNewNode(null);
  };

  const handleSaveLink = async () => {
    await firebase.db.runTransaction(async t => {
      const linkRef = firebase.db.collection("links").doc(selectedLink.id);
      linkRef.update({
        certainty: selectedLink.certainty,
        detail: selectedLink.detail,
        polarity: selectedLink.polarity
      });
      setTabIndex(0);
      setSelectedLink(null);
    });
  };

  const addChild = child => {
    setNewNode(prev => {
      const _prev = { ...prev };
      if (!_prev.children.includes(child)) {
        _prev.children.push(child);
      }
      return _prev;
    });
  };
  const removeChild = child => {
    setNewNode(prev => {
      const _prev = { ...prev };
      if (_prev.children.includes(child)) {
        _prev.children.splice(_prev.children.indexOf(child), 1);
      }
      return _prev;
    });
  };

  const handleOpenSidBar = () => {
    setOpenSideBar(old => !old);
  };

  const handleChangeDiagram = event => {
    const _diagram = diagrams.find(diagram => diagram.title === event.target.value);

    setSelectedDiagram(_diagram);
    setGroups([]);
    setNodes([]);
    setLinks([]);
  };

  const downloadSvg = () => {
    const svgElement = svgRef.current;
    if (svgElement) {
      const clone = svgElement.cloneNode(true);
      const svgStyles = document.createElement("style");
      const cssRules = Array.from(document.styleSheets)
        .reduce((acc, styleSheet) => {
          try {
            return acc.concat(Array.from(styleSheet.cssRules));
          } catch {
            return acc; // Ignore CSS rules we can't access
          }
        }, [])
        .map(rule => rule.cssText)
        .join(" ");

      svgStyles.textContent = cssRules;
      clone.insertBefore(svgStyles, clone.firstChild);

      const svgData = new XMLSerializer().serializeToString(clone);
      const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
      const url = URL.createObjectURL(svgBlob);

      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = "graph.svg";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      URL.revokeObjectURL(url);
    }
  };

  const generateNewDiagram = async () => {
    try {
      setLoadingResponse("generate");
      const {
        inputValue: documentDetailed,
        newDiagramTitle,
        problemStatement
      } = JSON.parse(await promptItDiagram("Generate a new diagram:", "Generate", "Cancel", "ideaEvaluator"));

      if (!documentDetailed.trim()) {
        setLoadingResponse(false);
        return;
      }

      if (!newDiagramTitle) {
        setLoadingResponse(false);
        return;
      }

      const {
        data: { diagramId }
      } = await axios.post("/generateCollabDiagram", {
        documentDetailed,
        newDiagramTitle,
        problemStatement,
        ideaEvaluator: true
      });
      setSelectedDiagram({
        id: diagramId,
        title: newDiagramTitle,
        documentDetailed
      });

      setLoadingResponse(false);
      // reviewDiagram();
    } catch (error) {
      console.error(error);
      await confirmIt("There was an error generating the diagram!", "Ok");
    } finally {
      setLoadingResponse(false);
    }
  };

  const reviewDiagram = async () => {
    try {
      setLoadingResponse("review");
      const feedback = await axios.post(
        "https://ontology-app-163479774214.us-central1.run.app/api/reviewCollabDiagram",
        {
          diagramId: selectedDiagram.id
        }
      );
      setReviewerFeedback(feedback.data.review);
      setReviewerPrompt(feedback.data.openAIPrompt);

      setOpenSideBar(true);
      setTabIndex(2);
      setSelectedLink(null);
      setNewNode(null);
      setLoadingResponse(false);
    } catch (error) {
      console.error(error);
      await confirmIt("There was an error reviewing the diagram!", "Ok");
    } finally {
      setLoadingResponse(false);
    }
  };

  const improveDiagram = async () => {
    try {
      setLoadingResponse("improve");
      //
      const { diagram } = await axios.post(
        "https://ontology-app-163479774214.us-central1.run.app/api/improveCollabDiagram",
        {
          diagramId: selectedDiagram.id,
          feedback: reviewerFeedback
        }
      );
      setSelectedDiagram(diagram);
      setLoadingResponse(false);
      setReviewerFeedback(null);
      setTabIndex(0);
    } catch (error) {
      console.error(error);
      await confirmIt("There was an error improving the diagram!", "ok");
    } finally {
      setLoadingResponse(null);
    }
  };
  const proposeSolutions = async () => {
    try {
      try {
        setLoadingResponse("proposeSolutions");
        //
        await axios.post("https://ontology-app-163479774214.us-central1.run.app/api/proposeSolutions", {
          diagramId: selectedDiagram.id
        });

        setTabIndex(
          reviewerFeedback && (newNode || selectedLink) ? 4 : reviewerFeedback || newNode || selectedLink ? 3 : 2
        );
        setLoadingResponse(false);
        setReviewerFeedback(null);
        setTabIndex(0);
      } catch (error) {
        console.error(error);
        await confirmIt("There was an error proposing Solutions for the diagram!", "ok");
      } finally {
        setLoadingResponse(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLink = async () => {
    if (await confirmIt("Are you sure you want to delete the link?", "Delete", "Keep")) {
      if (selectedLink) {
        const linkRef = firebase.db.collection("links").doc(selectedLink.id);
        linkRef.update({ deleted: true });
        setSelectedLink(null);
        setTabIndex(0);
      }
    }
  };
  const deleteDiagram = useCallback(async () => {
    if (!(await confirmIt("Are you sure you want to delete this diagram?", "Delete", "Keep"))) {
      return;
    }
    if (selectedDiagram) {
      const diagramRef = firebase.db.collection(DIAGRAMS).doc(selectedDiagram.id);
      diagramRef.update({
        deleted: true
      });

      for (let nodeId in nodes) {
        const nodeRef = firebase.db.collection(NODES).doc(nodeId);
        nodeRef.update({ deleted: true });
      }
      for (let link of links) {
        const linkRef = firebase.db.collection(LINKS).doc(link.id);
        linkRef.update({ deleted: true });
      }
      setSelectedDiagram({ id: "", title: "" });
    }
  }, [selectedDiagram?.id, nodes, links]);

  const copyDiagram = () => {
    const _nodes = Object.values(JSON.parse(JSON.stringify(nodes)));
    const _links = JSON.parse(JSON.stringify(links));
    const _groups = JSON.parse(JSON.stringify(groups));
    for (let node of _nodes) {
      delete node.id;
      delete node.diagrams;
      delete node.deleted;
      delete node.createdAt;
      node.groups = node.groups.map(c => c.label);
    }
    for (let link of _links) {
      link.source = nodes[link.source].label;
      link.target = nodes[link.target].label;
      delete link.id;
      delete link.diagrams;
      delete link.deleted;
    }
    for (let group of _groups) {
      delete group.createdAt;
      delete group.deleted;
      delete group.diagrams;
      delete group.id;
    }
    const responseFromModel = {
      nodes: _nodes,
      links: _links,
      groups: _groups
    };
    navigator.clipboard
      .writeText(JSON.stringify(responseFromModel, null, 2))
      .then(() => {
        setTempText("Copied!");
      })
      .catch(err => console.error("Failed to copy:", err));

    setTimeout(() => {
      setTempText("");
    }, 1000);
  };
  return (
    <Box
      sx={{
        backgroundColor: darkMode ? "#272727" : "",
        overflow: "hidden",
        "&::-webkit-scrollbar": {
          display: "none"
        }
      }}
    >
      {diagrams.length > 0 ? (
        <Box
          sx={{
            overflow: "hidden",
            "&::-webkit-scrollbar": {
              display: "none"
            }
          }}
        >
          <Grid container spacing={2} direction="row-reverse">
            <Grid
              item
              xs={openSideBar && !isMobile ? 9 : 12}
              sx={{
                height: "110vh",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  display: "none"
                }
              }}
            >
              <Box
                id="graphPaper"
                sx={{
                  ml: isMobile ? "9px" : "",
                  height: isMobile ? "530px" : "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
                elevation={4}
              >
                {Object.keys(nodes).length > 0 && <svg id="graphGroup" width="100%" height="100%" ref={svgRef}></svg>}
                {!openSideBar && (
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "3%",
                      left: "10px",
                      zIndex: "1000",
                      transform: "translateY(-50%)"
                    }}
                    onClick={handleOpenSidBar}
                  >
                    <MenuIcon sx={{ fontSize: "35px" }} />
                  </IconButton>
                )}

                <Box
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "-5px",
                    zIndex: "1000",
                    display: "flex",
                    gap: "22px"
                  }}
                >
                  {" "}
                  {tempText && <Typography sx={{ color: darkMode ? "white" : "black" }}>{tempText}</Typography>}
                  <Tooltip title={"Generate a diagram"} sx={{ mt: "3px" }}>
                    {loadingResponse && (loadingResponse === "generate" || loadingResponse === "improve") ? (
                      <Box
                        sx={{
                          width: "35px",
                          height: "35px",
                          border: "1px solid gray",
                          borderRadius: "10px",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <CircularProgress size={24} />
                      </Box>
                    ) : (
                      <IconButton
                        variant="contained"
                        onClick={generateNewDiagram}
                        sx={{ width: "35px", height: "35px", border: "1px solid gray", borderRadius: "10px" }}
                        disabled={!!loadingResponse || !!selectedSolutionId}
                      >
                        <AutoFixNormalIcon />
                      </IconButton>
                    )}
                  </Tooltip>
                  <Tooltip title={"Review Diagram"} sx={{ mt: "3px" }}>
                    {loadingResponse === "review" ? (
                      <Box
                        sx={{
                          width: "35px",
                          height: "35px",
                          border: "1px solid gray",
                          borderRadius: "10px",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <CircularProgress size={24} />
                      </Box>
                    ) : (
                      <IconButton
                        variant="contained"
                        onClick={reviewDiagram}
                        sx={{ width: "35px", height: "35px", border: "1px solid gray", borderRadius: "10px" }}
                        disabled={!!loadingResponse || !!selectedSolutionId}
                      >
                        <AutoAwesomeIcon />
                      </IconButton>
                    )}
                  </Tooltip>
                  <Tooltip title={"Propose Solutions"} sx={{ mt: "3px" }}>
                    {loadingResponse === "proposeSolutions" ? (
                      <Box
                        sx={{
                          width: "35px",
                          height: "35px",
                          border: "1px solid gray",
                          borderRadius: "10px",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <CircularProgress size={24} />
                      </Box>
                    ) : (
                      <IconButton
                        variant="contained"
                        onClick={proposeSolutions}
                        sx={{ width: "35px", height: "35px", border: "1px solid gray", borderRadius: "10px" }}
                        disabled={!!loadingResponse}
                      >
                        <EmojiObjectsIcon />
                      </IconButton>
                    )}
                  </Tooltip>
                  <Tooltip title={"Copy the JSON structure"}>
                    <IconButton
                      variant="contained"
                      onClick={copyDiagram}
                      sx={{ width: "35px", height: "35px", border: "1px solid gray", borderRadius: "10px" }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Delete current diagram"}>
                    <IconButton
                      variant="contained"
                      sx={{ width: "35px", height: "35px", border: "1px solid gray", borderRadius: "10px" }}
                      onClick={deleteDiagram}
                      disabled={loadingResponse}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Add new node"}>
                    <IconButton
                      variant="contained"
                      onClick={AddNewNode}
                      sx={{ width: "35px", height: "35px", border: "1px solid gray", borderRadius: "10px" }}
                      disabled={newNode?.new || loadingResponse}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}></Box>
                  {diagrams.length > 0 && (
                    <FormControl disabled={loadingResponse}>
                      <InputLabel>Diagram</InputLabel>
                      <Select
                        label="diagram"
                        value={selectedDiagram?.title || ""}
                        onChange={handleChangeDiagram}
                        sx={{
                          width: "200px",
                          border: "1px",
                          borderColor: "white",
                          borderRadius: "25px",
                          p: 0,
                          "& .MuiSelect-select": {
                            padding: 0,
                            p: "10px"
                          }
                        }}
                      >
                        {[...diagrams].map(diagram => (
                          <MenuItem key={diagram.id} value={diagram.title} sx={{ display: "center" }}>
                            {diagram.title}
                          </MenuItem>
                        ))}
                        {/*   <MenuItem
                        key={"diagram.id"}
                        value={""}
                        sx={{
                          display: "center",
                          backgroundColor: "orange",
                          borderRadius: "25px",
                          alignItems: "center",
                          textAlign: "center"
                        }}
                      >
                        <AddIcon /> <Typography>Add diagram</Typography>
                      </MenuItem> */}
                      </Select>
                    </FormControl>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexWrap: "wrap",
                      alignItems: "center"
                    }}
                  >
                    <Tooltip title={"Download as SVG"}>
                      <IconButton
                        variant="contained"
                        onClick={downloadSvg}
                        sx={{ width: "35px", height: "35px", border: "1px solid gray", borderRadius: "10px" }}
                      >
                        <GetAppIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <FormControlLabel
                    control={
                      <Tooltip title={darkMode ? "Turn on the light" : "Turn off the light"}>
                        <Box
                          onClick={toggleTheme}
                          sx={{
                            border: "1px solid gray",
                            borderRadius: "10px",
                            p: 0.5,
                            pb: 0.3,
                            ":hover": {
                              backgroundColor: !darkMode ? "#e0e0e0" : "gray"
                            }
                          }}
                        >
                          {darkMode ? <WbSunnyIcon sx={{ color: "white" }} /> : <BedtimeIcon sx={{ color: "gray" }} />}
                        </Box>
                      </Tooltip>
                    }
                  />
                </Box>

                <Box
                  sx={{
                    position: "absolute",
                    bottom: "-3.5%",
                    left: openSideBar ? "25%" : "10px",
                    zIndex: "1000",
                    transform: "translateY(-50%)"
                  }}
                >
                  {" "}
                  <Box
                    sx={{
                      border: "5px solid orange",
                      width: "120px",
                      height: "35px",
                      mb: "13px",
                      display: "flex",
                      borderRadius: "10px",
                      alignItems: "center",
                      color: "orange",
                      justifyContent: "center"
                    }}
                  >
                    <Typography>leverage node</Typography>
                  </Box>
                  {Object.keys(nodes).length > 0 && !isMobile && (
                    <Box sx={{ display: "flex" }}>
                      {Object.values(nodeTypes)
                        .sort((a, b) => {
                          const isOtherA = a.type.toLowerCase() === "other";
                          const isOtherB = b.type.toLowerCase() === "other";

                          if (isOtherA && !isOtherB) return 1;
                          if (!isOtherA && isOtherB) return -1;

                          return 0;
                        })
                        .map((resource, index) => (
                          <ColorBox
                            id={resource.id}
                            key={resource.type + index}
                            text={resource.type}
                            color={resource.color}
                          />
                        ))}
                      {editor && (
                        <Tooltip title="Add new node type">
                          <IconButton
                            sx={{
                              display: "flex",
                              borderRadius: "50%",
                              alignItems: "center",
                              fontSize: 13,
                              ml: 1,
                              mr: 3,
                              textAlign: "center",
                              width: "40px",
                              height: "40px",
                              border: darkMode ? "1px solid white" : "1px solid black"
                            }}
                            onClick={() => {
                              setIsModalAddTypeOpen(true);
                            }}
                            variant="outlined"
                          >
                            <AddIcon sx={{ color: darkMode ? "white" : "black" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Box sx={{ gap: "13px", display: "flex", mx: "6px" }}>
                        {Object.entries(LINKS_TYPES).map(resource => (
                          <Box key={resource[0]} sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <TrendingFlatIcon style={{ fontSize: "40px", color: resource[1].color }} />
                            <Typography sx={{ fontSize: "14px", color: resource[1].color }}>{resource[0]}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={openSideBar && !isMobile ? 3 : 0}>
              {openSideBar && (
                <Paper
                  sx={{
                    height: "100vh",
                    overflowY: "auto",
                    direction: "rtl",
                    backgroundColor: darkMode ? "#4b4949" : "#f5f5f5",
                    boxShadow: 3,
                    borderRight: darkMode ? "1px solid white" : "1px solid black",
                    position: "relative",
                    "&::-webkit-scrollbar": {
                      display: "none"
                    }
                  }}
                >
                  <IconButton
                    onClick={() => setOpenSideBar(false)}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: "-20px",
                      transform: "translateY(-50%)",
                      backgroundColor: darkMode ? "#333" : "gray",
                      color: "white",
                      boxShadow: 2,
                      "&:hover": {
                        backgroundColor: darkMode ? "#555" : "#ddd"
                      },
                      pl: "4px",
                      pr: "15px",
                      pt: "14px",
                      pb: "14px"
                    }}
                  >
                    <ArrowBackIosIcon sx={{ fontSize: "19px" }} />
                  </IconButton>

                  <Box
                    sx={{
                      direction: "ltr",
                      "&::-webkit-scrollbar": {
                        display: "none"
                      },
                      mb: 4
                    }}
                  >
                    <Box
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 10,
                        p: 1,
                        backgroundColor: darkMode ? "#4b4949" : "#f5f5f5"
                      }}
                    >
                      <Box sx={{ display: "flex", justifyContent: "space-between", overflowX: "auto" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
                          <Tabs
                            value={tabIndex}
                            onChange={(e, newValue) => setTabIndex(newValue)}
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                          >
                            <Tab label="Groups" {...a11yProps(0)} sx={{ textTransform: "none" }} />
                            <Tab label="Feedback Loops" {...a11yProps(1)} sx={{ textTransform: "none" }} />
                            {reviewerFeedback && (
                              <Tab label="Reviewer" {...a11yProps(2)} sx={{ textTransform: "none" }} />
                            )}
                            {(newNode || selectedLink) && (
                              <Tab
                                label={`${newNode?.new ? "Add" : "Edit"} ${newNode ? "Node" : "Link"}`}
                                {...a11yProps(reviewerFeedback ? 3 : 2)}
                                sx={{ textTransform: "none" }}
                              />
                            )}
                            {proposedSolutions.length > 0 && (
                              <Tab
                                label={"Solutions"}
                                {...a11yProps(
                                  reviewerFeedback && (newNode || selectedLink)
                                    ? 4
                                    : reviewerFeedback || newNode || selectedLink
                                    ? 3
                                    : 2
                                )}
                                sx={{ textTransform: "none" }}
                              />
                            )}
                          </Tabs>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <CustomTabPanel value={tabIndex} index={0}>
                        <Typography sx={{ fontWeight: "bold", fontSize: "20px", mb: "15px" }}>
                          Choose groups to show their causal relations:
                        </Typography>
                        <CollabTree
                          data={groups}
                          setData={setGroups}
                          setSelectedGroups={setSelectedGroups}
                          selectedGroups={selectedGroups}
                          diagramId={selectedDiagram?.id}
                        />
                      </CustomTabPanel>
                      <CustomTabPanel value={tabIndex} index={1}>
                        {Object.keys(reinforcementLoops).length > 0 ? (
                          <ReinforcementLoopsDisplay
                            reinforcementLoops={reinforcementLoops}
                            nodes={nodes}
                            selectedLoop={selectedLoop}
                            setSelectedLoop={setSelectedLoop}
                            darkMode={darkMode}
                          />
                        ) : (
                          <Box>No reinforcement loops detected!</Box>
                        )}
                      </CustomTabPanel>
                      {reviewerFeedback && (
                        <CustomTabPanel value={tabIndex} index={2}>
                          <Box sx={{ border: "1px solid gray", p: 2, borderRadius: "25px" }}>
                            <Typography sx={{ my: "14px" }}>
                              <strong style={{ paddingRight: "14px" }}>Evaluation:</strong>
                              <Select
                                value={reviewerFeedback.evaluation}
                                onChange={event => {
                                  setReviewerFeedback({ ...reviewerFeedback, evaluation: event.target.value });
                                }}
                                sx={{
                                  color: reviewerFeedback.evaluation === "reject" ? "red" : "green",
                                  fontSize: "18px",
                                  textTransform: "capitalize",
                                  p: 0,
                                  borderRadius: "25px",
                                  "& .MuiSelect-select": {
                                    padding: 0,
                                    p: "10px"
                                  }
                                }}
                              >
                                <MenuItem value="accept">Accepted</MenuItem>
                                <MenuItem value="reject">Rejected</MenuItem>
                              </Select>
                            </Typography>

                            <TextField
                              label="Feedback"
                              variant="outlined"
                              value={reviewerFeedback.feedback}
                              onChange={e => {
                                setReviewerFeedback(prev => ({
                                  ...prev,
                                  feedback: e.target.value
                                }));
                              }}
                              fullWidth
                              multiline
                              sx={{ width: "95%", m: 0.5 }}
                            />
                          </Box>

                          {reviewerFeedback.evaluation === "reject" && (
                            <LoadingButton
                              sx={{
                                textTransform: "none",
                                borderRadius: "25px",
                                mt: "13px",
                                backgroundColor: "orange",
                                color: "white",
                                mr: "5px"
                              }}
                              variant="contained"
                              onClick={improveDiagram}
                              loading={loadingResponse === "improve"}
                            >
                              Improve the diagram
                            </LoadingButton>
                          )}

                          {reviewerPrompt && (
                            <Button
                              onClick={() => {
                                setIsPromptModalOpen(true);
                              }}
                              sx={{
                                position: "sticky",
                                bottom: 0,
                                mt: "15px",
                                borderRadius: "25px",
                                textTransform: "none",
                                color: "white"
                              }}
                              variant="contained"
                              color="info"
                            >
                              View used prompt
                            </Button>
                          )}
                        </CustomTabPanel>
                      )}
                      {(newNode || selectedLink) && (
                        <CustomTabPanel value={tabIndex} index={reviewerFeedback ? 3 : 2}>
                          {" "}
                          {newNode ? (
                            <NodeEditor
                              newNode={newNode}
                              setNewNode={setNewNode}
                              nodeTypes={nodeTypes}
                              nodes={nodes}
                              groups={groups}
                              handleSave={handleSave}
                              handleClose={handleClose}
                              deleteNode={deleteNode}
                              darkMode={darkMode}
                              selectedDiagram={selectedDiagram}
                            />
                          ) : (
                            <LinkEditor
                              selectedLink={selectedLink}
                              nodes={nodes}
                              darkMode={darkMode}
                              selectedDiagram={selectedDiagram}
                              setSelectedLink={setSelectedLink}
                              handleSaveLink={handleSaveLink}
                              deleteLink={deleteLink}
                              onCancel={() => {
                                setSelectedLink(null);
                                setTabIndex(0);
                              }}
                            />
                          )}
                        </CustomTabPanel>
                      )}
                      <CustomTabPanel
                        value={tabIndex}
                        index={
                          reviewerFeedback && (newNode || selectedLink)
                            ? 4
                            : reviewerFeedback || newNode || selectedLink
                            ? 3
                            : 2
                        }
                      >
                        {proposedSolutions.length > 0 && (
                          <SolutionsTab
                            solutions={proposedSolutions}
                            selectedSolutionId={selectedSolutionId}
                            setSelectedSolutionId={setSelectedSolutionId}
                          />
                        )}
                      </CustomTabPanel>
                    </Box>
                  </Box>
                </Paper>
              )}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: darkMode ? "#272727" : "",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Box>
            {loadingResponse && <Typography sx={{ color: "white", mb: "14px" }}>Generating the diagram...</Typography>}
            <LoadingButton variant="contained" onClick={generateNewDiagram} loading={loadingResponse}>
              Generate a diagram
            </LoadingButton>
          </Box>
        </Box>
      )}
      {editor && (
        <AddNodeTypeModal
          open={isModalAddTypeOpen}
          onClose={() => {
            setIsModalAddTypeOpen(false);
            setEditNodeType(null);
          }}
          onSave={saveNewType}
          editNodeType={editNodeType}
        />
      )}
      {ConfirmDialog}
      {PromptDialog}
      <PromptViewer
        isPromptModalOpen={isPromptModalOpen}
        setIsPromptModalOpen={setIsPromptModalOpen}
        reviewerPrompt={reviewerPrompt}
        darkMode={darkMode}
      />
    </Box>
  );
};
export default IdeaEvaluator;

/* 
Analyze information to determine, recommend, and plan installation of a new system or modification of an existing system.
Analyze user needs and software requirements to determine feasibility of design within time and cost constraints.
Confer with data processing or project managers to obtain information on limitations or capabilities for data processing projects.
Confer with systems analysts, engineers, programmers and others to design systems and to obtain information on project limitations and capabilities, performance requirements and interfaces.
Consult with customers or other departments on project status, proposals, or technical issues, such as software system design or maintenance.
Coordinate installation of software system.
Design, develop and modify software systems, using scientific analysis and mathematical models to predict and measure outcomes and consequences of design.
Determine system performance standards.
Develop or direct software system testing or validation procedures, programming, or documentation.
Modify existing software to correct errors, adapt it to new hardware, or upgrade interfaces and improve performance.
Monitor functioning of equipment to ensure system operates in conformance with specifications.
Obtain and evaluate information on factors such as reporting formats required, costs, or security needs to determine hardware configuration.
Prepare reports or correspondence concerning project specifications, activities, or status.
Recommend purchase of equipment to control dust, temperature, or humidity in area of system installation.
Specify power supply requirements and configuration.
Store, retrieve, and manipulate data for analysis of system capabilities and requirements.
Supervise and assign work to programmers, designers, technologists, technicians, or other engineering or scientific personnel.
Supervise the work of programmers, technologists and technicians and other engineering and scientific personnel.
Train users to use new or modified equipment.
Analyze project data to determine specifications or requirements.
Modify software programs to improve performance.
Supervise information technology personnel.
Apply mathematical principles or statistical approaches to solve problems in scientific or applied fields.
Assess database performance.
Assign duties or work schedules to employees.
Collaborate with others to determine design specifications or details.
Collaborate with others to resolve information technology issues.
Communicate project information to others.
Coordinate software or hardware installation.
Design software applications.
Develop performance metrics or standards related to information technology.
Develop testing routines or procedures.
Document technical specifications or requirements.
Identify information technology project resource requirements.
Manage information technology projects or system activities.
Monitor computer system performance to ensure proper operation.
Prepare data for analysis.
Provide recommendations to others about computer hardware.
Provide technical support for software maintenance or use.
Teach others to use computer equipment or hardware.
Identify, analyze, and document problems with program function, output, online screen, or content.
Document software defects, using a bug tracking system, and report defects to software developers.
Develop testing programs that address areas such as database impacts, software scenarios, regression testing, negative testing, error or bug retests, or usability.
Design test plans, scenarios, scripts, or procedures.
Document test procedures to ensure replicability and compliance with standards.
Provide feedback and recommendations to developers on software usability and functionality.
Install, maintain, or use software testing programs.
Test system modifications to prepare for implementation.
Create or maintain databases of known test defects.
Develop or specify standards, methods, or procedures to determine product quality or release readiness.
Monitor bug resolution efforts and track successes.
Update automated test scripts to ensure currency.
Participate in product design reviews to provide input on functional requirements, product designs, schedules, or potential problems.
Plan test schedules or strategies in accordance with project scope or delivery dates.
Monitor program performance to ensure efficient and problem-free operations.
Conduct software compatibility tests with programs, hardware, operating systems, or network environments.
Investigate customer problems referred by technical support.
Review software documentation to ensure technical accuracy, compliance, or completeness, or to mitigate risks.
Identify program deviance from standards, and suggest modifications to ensure compliance.
Perform initial debugging procedures by reviewing configuration files, logs, or code pieces to determine breakdown source.
Design or develop automated testing tools.
Install and configure recreations of software production environments to allow testing of software performance.
Collaborate with field staff or customers to evaluate or diagnose problems and recommend possible solutions.
Coordinate user or third-party testing.
Visit beta testing sites to evaluate software performance.
Conduct historical analyses of test results.
Evaluate or recommend software for testing or bug tracking.
Modify existing software to correct errors, allow it to adapt to new hardware, or to improve its performance.
Recommend purchase of equipment to control dust, temperature, or humidity in area of system installation.
Store, retrieve, and manipulate data for analysis of system capabilities and requirements.
Working with Computers — Using computers and computer systems (including hardware and software) to program, write software, set up functions, enter data, or process information.
Getting Information — Observing, receiving, and otherwise obtaining information from all relevant sources.
Communicating with Supervisors, Peers, or Subordinates — Providing information to supervisors, co-workers, and subordinates by telephone, in written form, e-mail, or in person.
Processing Information — Compiling, coding, categorizing, calculating, tabulating, auditing, or verifying information or data.
Updating and Using Relevant Knowledge — Keeping up-to-date technically and applying new knowledge to your job.
Analyzing Data or Information — Identifying the underlying principles, reasons, or facts of information by breaking down information or data into separate parts.
Evaluating Information to Determine Compliance with Standards — Using relevant information and individual judgment to determine whether events or processes comply with laws, regulations, or standards.
Identifying Objects, Actions, and Events — Identifying information by categorizing, estimating, recognizing differences or similarities, and detecting changes in circumstances or events.
Documenting/Recording Information — Entering, transcribing, recording, storing, or maintaining information in written or electronic/magnetic form.
Organizing, Planning, and Prioritizing Work — Developing specific goals and plans to prioritize, organize, and accomplish your work.
Establishing and Maintaining Interpersonal Relationships — Developing constructive and cooperative working relationships with others, and maintaining them over time.
Making Decisions and Solving Problems — Analyzing information and evaluating results to choose the best solution and solve problems.
Thinking Creatively — Developing, designing, or creating new applications, ideas, relationships, systems, or products, including artistic contributions.
Monitoring Processes, Materials, or Surroundings — Monitoring and reviewing information from materials, events, or the environment, to detect or assess problems.
Coordinating the Work and Activities of Others — Getting members of a group to work together to accomplish tasks.
Developing and Building Teams — Encouraging and building mutual trust, respect, and cooperation among team members.
Judging the Qualities of Objects, Services, or People — Assessing the value, importance, or quality of things or people.
Interpreting the Meaning of Information for Others — Translating or explaining what information means and how it can be used.
Scheduling Work and Activities — Scheduling events, programs, and activities, as well as the work of others.
Coaching and Developing Others — Identifying the developmental needs of others and coaching, mentoring, or otherwise helping others to improve their knowledge or skills.
Developing Objectives and Strategies — Establishing long-range objectives and specifying the strategies and actions to achieve them.
Providing Consultation and Advice to Others — Providing guidance and expert advice to management or other groups on technical, systems-, or process-related topics.
Training and Teaching Others — Identifying the educational needs of others, developing formal educational or training programs or classes, and teaching or instructing others.
*/
