import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ReinforcementLoopsDisplay = ({ reinforcementLoops, nodes, selectedLoop, setSelectedLoop, darkMode }) => {
  const formatCycle = cycle =>
    (cycle || []).map((id, index) => (
      <React.Fragment key={id}>
        {nodes[id]?.label || id}
        {index < cycle.length - 1 && <ArrowForwardIcon fontSize="small" sx={{ verticalAlign: "middle", mx: 0.5 }} />}
      </React.Fragment>
    ));

  return (
    <Box>
      <Box>
        {Object.keys(reinforcementLoops).map(key => (
          <Accordion key={key} defaultExpanded sx={{ borderRadius: "12px" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{key}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: "5px", mb: "15px", borderRadius: "25px" }}>
              {reinforcementLoops[key].length > 0 ? (
                <List sx={{ p: 0 }}>
                  {reinforcementLoops[key].map((loop, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        marginBottom: 2,
                        gap: "13px",
                        p: 0,
                        m: 0,
                        cursor: "pointer",
                        borderRadius: "8px",
                        transition: "background-color 0.3s ease",
                        "&:hover": {
                          backgroundColor: darkMode ? "#545252" : "#f0f0f0"
                        }
                      }}
                      onClick={e => {
                        e.stopPropagation();
                        setSelectedLoop(prev => (prev?.loopNodes === loop.loopNodes ? null : loop));
                      }}
                    >
                      <Checkbox
                        checked={selectedLoop?.loopNodes === loop.loopNodes}
                        sx={{ p: 0 }}
                        onClick={e => {
                          e.stopPropagation();
                          setSelectedLoop(prev => (prev?.loopNodes === loop.loopNodes ? null : loop));
                        }}
                      />
                      <ListItemText primary={<Typography variant="body1">{formatCycle(loop.loopNodes)}</Typography>} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography sx={{ color: "gray", fontSize: "13px" }}>No {key}s detected!</Typography>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default ReinforcementLoopsDisplay;
