import { Button, TextField, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { firebaseState, isAdminState } from "../../store/AuthAtoms";
import Box from "@mui/material/Box";
import { debounce } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";

const PromptDialogComponent = ({ open, onClose, message, confirmation, cancel, loadingResponse }) => {
  const admin = true; /* useRecoilValue(isAdminState) */
  const isAdmin = useRecoilValue(isAdminState);
  const [inputValue, setInputValue] = useState("");
  const [llmPrompt, setLlmPrompt] = useState("");
  const [consultingTopic, setConsultingTopic] = useState("");
  const [ideaEvaluator, setIdeaEvaluator] = useState(false);
  const [problemStatement, setProblemStatement] = useState("");
  const firebase = useRecoilValue(firebaseState);

  useEffect(() => {
    getPrompt();
  }, [confirmation]);
  console.log(isAdmin, " ==>");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSavePrompt = useCallback(
    debounce(async promptToSave => {
      const promptDocs = await firebase.db
        .collection("diagramPrompts")
        .where("consultant", "==", true)
        .where("type", "==", "generate")
        .get();

      if (promptDocs.docs.length > 0) {
        await promptDocs.docs[0].ref.set({
          prompt: promptToSave,
          type: confirmation.toLowerCase(),
          consultant: true
        });
      } else {
        const promptRef = firebase.db.collection("diagramPrompts").doc();
        await promptRef.set({
          prompt: promptToSave,
          type: confirmation.toLowerCase(),
          consultant: true
        });
      }
    }, 1000),
    [firebase.db, confirmation]
  );

  const getPrompt = async () => {
    const promptDocs = await firebase.db
      .collection("diagramPrompts")
      .where("consultant", "==", true)
      .where("type", "==", "generate")
      .get();

    if (promptDocs.docs.length > 0) {
      const promptData = promptDocs.docs[0].data();
      setLlmPrompt(promptData?.prompt || "");
    }
  };

  const handleClose = async confirmed => {
    // Save immediately when closing
    await savePrompt();
    await onClose({ documentDetailed: inputValue, consultingTopic, problemStatement });
  };

  const handleUserInputChange = event => {
    setInputValue(event.target.value);
  };

  const handleProblemStatementChange = event => {
    setProblemStatement(event.target.value);
  };

  const handleLlmPromptChange = event => {
    const newPrompt = event.target.value;
    setLlmPrompt(newPrompt);
    debouncedSavePrompt(newPrompt);
  };

  const savePrompt = async () => {
    debouncedSavePrompt.cancel();

    const promptDocs = await firebase.db
      .collection("diagramPrompts")
      .where("consultant", "==", true)
      .where("type", "==", "generate")
      .get();

    if (promptDocs.docs.length > 0) {
      await promptDocs.docs[0].ref.set({
        prompt: llmPrompt,
        type: confirmation.toLowerCase(),
        consultant: true
      });
    } else {
      const promptRef = firebase.db.collection("diagramPrompts").doc();
      await promptRef.set({
        prompt: llmPrompt,
        type: confirmation.toLowerCase(),
        consultant: true
      });
    }
  };

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSavePrompt.cancel();
    };
  }, [debouncedSavePrompt]);
  if (loadingResponse === "generate") {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          minHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress size={80} />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 3,
        p: 2
      }}
    >
      <TextField
        label="Consulting Topic"
        autoFocus
        margin="dense"
        type="text"
        value={consultingTopic}
        onChange={e => setConsultingTopic(e.target.value)}
        fullWidth
        variant="outlined"
        sx={{
          maxWidth: "500px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "12px"
          }
        }}
      />

      <Grid container spacing={2} sx={{ flexGrow: 1, overflow: "hidden" }}>
        <Grid item xs={admin ? 6 : 12} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="subtitle1" fontWeight={600} color="text.primary">
            {ideaEvaluator
              ? "Enter the case description below:"
              : confirmation.toLowerCase() === "generate"
              ? "Enter your document below:"
              : "Enter the case description below:"}
          </Typography>
          <TextField
            placeholder={"Case description..."}
            value={inputValue}
            onChange={handleUserInputChange}
            fullWidth
            multiline
            minRows={14}
            maxRows={ideaEvaluator ? 14 : 500}
            variant="outlined"
            sx={{
              flexGrow: 1,
              overflow: "auto",
              borderRadius: "12px",
              maxHeight: "75vh",
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px"
              }
            }}
          />

          <>
            <Typography variant="subtitle1" fontWeight={600} color="text.primary">
              Explain the problem below:
            </Typography>
            <TextField
              placeholder="Problem description..."
              value={problemStatement}
              onChange={handleProblemStatementChange}
              fullWidth
              multiline
              minRows={14}
              maxRows={14}
              variant="outlined"
              sx={{
                flexGrow: 1,
                overflow: "auto",
                borderRadius: "12px",
                maxHeight: "75vh",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px"
                }
              }}
            />
          </>
        </Grid>

        {admin && (
          <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="subtitle1" fontWeight={600} color="text.primary">
              System Prompt:
            </Typography>
            <TextField
              placeholder="Enter system prompt here..."
              value={llmPrompt}
              onChange={handleLlmPromptChange}
              fullWidth
              multiline
              minRows={14}
              variant="outlined"
              sx={{
                flexGrow: 1,
                overflow: "auto",
                borderRadius: "12px",
                maxHeight: "75vh",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px"
                }
              }}
            />
          </Grid>
        )}
      </Grid>

      <Box sx={{ display: "flex", alignItems: "center", alignContent: "center", ml: "40%" }}>
        <Button
          onClick={() => handleClose(true)}
          variant="contained"
          sx={{
            borderRadius: "26px",
            backgroundColor: "orange",
            color: "white",
            px: 4,
            py: 1.5,
            fontSize: "16px",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "darkorange"
            },
            "&:disabled": {
              backgroundColor: "action.disabledBackground"
            }
          }}
          // disabled={!inputValue.trim() || !consultingTopic.trim()}
        >
          {confirmation}
        </Button>
      </Box>
    </Box>
  );
};

export default PromptDialogComponent;
