import { Button, Modal, Box, Typography, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const PromptViewer = ({ reviewerPrompt, isPromptModalOpen, setIsPromptModalOpen, darkMode }) => {
  return (
    <div>
      <Button variant="contained" onClick={() => setIsPromptModalOpen(true)}>
        View System Prompt
      </Button>

      <Modal open={isPromptModalOpen} onClose={() => setIsPromptModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "100%",
            overflowY: "auto",
            width: "100%",
            borderRadius: 2
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "sticky",
              top: 0,
              background: "inherit",
              zIndex: 1,
              pb: 1
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                width: "100%",
                fontSize: "22px",
                py: "4px",
                color: darkMode ? "white" : "black"
              }}
            >
              Reviewer Prompt
            </Typography>
            <IconButton
              onClick={() => setIsPromptModalOpen(false)}
              variant="outlined"
              size="small"
              sx={{ m: 3, backgroundColor: "gray" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography sx={{ whiteSpace: "pre-wrap", p: 3, color: darkMode ? "white" : "black" }}>
            {reviewerPrompt}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default PromptViewer;
