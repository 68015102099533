import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Grid,
  Typography
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { firebaseState } from "../store/AuthAtoms";
import Box from "@mui/material/Box";

const useDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [inputValue, setInputValue] = useState("");
  const resolveRef = React.useRef(null);
  const [confirmation, setConfirmation] = useState("");
  const [cancel, setCancel] = useState("");
  const firebase = useRecoilValue(firebaseState);
  const [llmPrompt, setLlmPrompt] = useState("");
  const [newDiagramTitle, setNewDiagramTitle] = useState("");
  const [ideaEvaluator, setIdeaEvaluator] = useState(false);
  const [problemStatement, setProblemStatement] = useState("");

  const getPrompt = async type => {
    const promptDocs = ideaEvaluator
      ? await firebase.db
          .collection("diagramPrompts")
          .where("ideaEvaluator", "==", true)
          .where("type", "==", "generate")
          .get()
      : await firebase.db.collection("diagramPrompts").where("type", "==", "generate").get();
    if (promptDocs.docs.length > 0) {
      const promptData = promptDocs.docs[0].data();
      setLlmPrompt(promptData?.prompt || "");
    }
  };

  const showDialog = useCallback((message, confirmation, cancel, type) => {
    getPrompt(confirmation.toLowerCase());
    setDialogMessage(message);
    setIsOpen(true);
    setConfirmation(confirmation);
    setCancel(cancel);
    if (type === "ideaEvaluator") {
      setIdeaEvaluator(true);
    }
    return new Promise(resolve => {
      resolveRef.current = resolve;
    });
  }, []);

  const closeDialog = useCallback(async () => {
    setIsOpen(false);
    setDialogMessage("");
    setInputValue("");
    setLlmPrompt("");
    await savePrompt();

    if (resolveRef.current) {
      resolveRef.current(JSON.stringify({ inputValue, newDiagramTitle, problemStatement }));
    }
  }, [inputValue, llmPrompt, newDiagramTitle, problemStatement]);

  const handleUserInputChange = event => {
    setInputValue(event.target.value);
  };
  const handleProblemStatementChange = event => {
    setProblemStatement(event.target.value);
  };

  const handleLlmPromptChange = event => {
    setLlmPrompt(event.target.value);
  };
  const savePrompt = async () => {
    const promptDocs = ideaEvaluator
      ? await firebase.db
          .collection("diagramPrompts")
          .where("ideaEvaluator", "==", true)
          .where("type", "==", "generate")
          .get()
      : await firebase.db.collection("diagramPrompts").where("type", "==", "generate").get();
    if (promptDocs.docs.length > 0) {
      await promptDocs.docs[0].ref.set({
        prompt: llmPrompt,
        type: confirmation.toLowerCase(),
        ...(ideaEvaluator ? { ideaEvaluator: true } : {})
      });
    } else {
      const promptRef = firebase.db.collection("diagramPrompts").doc();
      await promptRef.set({
        prompt: llmPrompt,
        type: confirmation.toLowerCase(),
        ...(ideaEvaluator ? { ideaEvaluator: true } : {})
      });
    }
  };

  const PromptDialog = (
    <Dialog open={isOpen} fullScreen onClose={closeDialog}>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          gap: 2,
          px: 4
        }}
      >
        <DialogContentText sx={{ fontSize: "22px", fontWeight: "bold", textAlign: "center", mb: 2 }}>
          {dialogMessage}
        </DialogContentText>

        <TextField
          label="Diagram Title"
          autoFocus
          margin="dense"
          type="text"
          value={newDiagramTitle}
          onChange={e => setNewDiagramTitle(e.target.value)}
          fullWidth
          variant="outlined"
          sx={{ maxWidth: "500px" }}
        />

        <Grid container spacing={2} sx={{ flexGrow: 1, overflow: "hidden" }}>
          <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography fontWeight="bold">
              {ideaEvaluator
                ? "Enter the case description below:"
                : confirmation.toLowerCase() === "generate"
                ? "Enter your document below:"
                : "Your input:"}
            </Typography>
            <TextField
              placeholder="Case description..."
              value={inputValue}
              onChange={handleUserInputChange}
              fullWidth
              multiline
              minRows={14}
              maxRows={ideaEvaluator ? 14 : 500}
              variant="outlined"
              sx={{ flexGrow: 1, overflow: "auto", borderRadius: "10px", maxHeight: "75vh" }}
            />
            {ideaEvaluator && <Typography fontWeight="bold">Enter the problem statement below:</Typography>}
            {ideaEvaluator && (
              <TextField
                placeholder="Problem statement..."
                value={problemStatement}
                onChange={handleProblemStatementChange}
                fullWidth
                multiline
                minRows={14}
                maxRows={ideaEvaluator ? 14 : 500}
                variant="outlined"
                sx={{ flexGrow: 1, overflow: "auto", borderRadius: "10px", maxHeight: "75vh" }}
              />
            )}
          </Grid>

          <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography fontWeight="bold">System Prompt:</Typography>
            <TextField
              placeholder="Enter system prompt here..."
              value={llmPrompt}
              onChange={handleLlmPromptChange}
              fullWidth
              multiline
              minRows={14}
              variant="outlined"
              sx={{ flexGrow: 1, overflow: "auto", borderRadius: "10px", maxHeight: "75vh" }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center", pb: 3, gap: 2 }}>
        <Button
          onClick={closeDialog}
          variant="contained"
          sx={{
            borderRadius: "26px",
            backgroundColor: "orange",
            color: "white",
            px: 4,
            py: 1,
            fontSize: "16px",
            fontWeight: "bold"
          }}
          disabled={!inputValue.trim() || !newDiagramTitle.trim()}
        >
          {confirmation}
        </Button>

        {cancel && (
          <Button
            onClick={() => closeDialog(false)}
            color="primary"
            variant="outlined"
            sx={{
              borderRadius: "26px",
              px: 4,
              py: 1,
              fontSize: "16px",
              fontWeight: "bold"
            }}
          >
            {cancel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

  const promptItDiagram = useCallback(
    (message, confirmation, cancel, type) => showDialog(message, confirmation, cancel, type),
    [showDialog]
  );

  return { promptItDiagram, PromptDialog };
};

export default useDialog;
